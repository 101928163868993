import React from 'react';
import './App.css';
import FormPage from './components/FormPage';
import LoginForm from './components/LoginForm';
import EntriesList from './components/EntriesList';
import footerChristmas from './images/footer.png';
import { CssBaseline, Container } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  const appContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  };

  const mainContentStyle = {
    flex: 1,  // Takes up the remaining space, pushing the footer to the bottom
  };

  const footerStyle = {
    width: '100%',
    backgroundImage: `url(${footerChristmas})`,
    backgroundSize: 'contain',
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
    height: '100px',

    // Additional responsive tweaks for mobile
    '@media (max-width: 768px)': {
      backgroundSize: 'cover',  // Cover the full width on smaller screens
      backgroundPosition: 'center',  // Center the image
      height: '150px',  // Increase height for mobile
    },
  };

  return (
    <Router>
      <div style={appContainerStyle}>
        <Container component="main" maxWidth="xl" className="container" disableGutters style={mainContentStyle}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<FormPage />} />
            <Route path="/listaentradas" element={<EntriesList />} />
            <Route path="/login" element={<LoginForm />} />
          </Routes>
        </Container>
        {/* Footer Section */}
        <div style={footerStyle} />
      </div>
    </Router>
  );
}

export default App;