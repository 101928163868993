const tiendas = [ // store the list in a constant for clarity
  "ÚTILES DE HONDURAS",
  "COOPERATIVA ELGA",
  "COOPERATIVA USULA",
  "BANCO CUSCATLAN",
  "FICOHSA",
  "IDH MICROFINANCIERA",
  "COOPERATIVA SAGRADA FAMILIA",
  "VD FASHION",
  "COFFEE XPRESS",
  "TECNI-ADVANCELL",
  "MI PEQUEÑO ANGEL",
  "BODYCARE",
  "DHL",
  "PAIZ",
  "FARMA FÁCIL",
  "PACER",
  "METROCINEMAS",
  "BAC AGENCIA DIGITAL",
  "FARMACIA SIMAN",
  "GRANO DE ORO",
  "SAINT MICHEL PARFUMS",
  "PACASA",
  "DAVIVIENDA",
  "BANCO ATLÁNTIDA",
  "CHARLY",
  "BAC",
  "GANANDO CON EL FUTBOL",
  "CITYNET",
  "LA BAGUETTE",
  "VOSI MANUALIDADES",
  "CLARO",
  "ÓPTICA MATAMOROS",
  "GLAM",
  "POLLO CAMPESINO",
  "EL PALACIO DE LOS NIÑOS",
  "FEDEX",
  "HAPPY KIDS (MÁQUINAS DE JUEGO)",
  "FÁNATICOS (MÁQUINA DE JUEGO)",
  "CELLMARKET",
  "BONSAI BEAUTY SUPPLY",
  "CUEROS BENJI/MANUFACTURA",
  "COMPASS",
  "ATENCIÓN GESTIONES DE ESTACIONAMIENTO",
  "TURASER",
  "VARIEDADES REYNA",
  "TROPIGAS",
  "TECNOMEDIA CLARO",
  "FICENSA",
  "OPTI-VISIÓN",
  "CHICOCELL",
  "BANCO DE OCCIDENTE",
  "TECNOMOBIL",
  "NICOLATIS",
  "CARIÑITOS",
  "BASILIO´S",
  "CREDIMAS",
  "FANTASIAS",
  "BK POSTRES",
  "BANPAIS",
  "ILUSSION COLOR",
  "ALUPAC",
  "PAR2",
  "JETSTEREO",
  "ÓPTICA SANTA LUCÍA",
  "THE PLACE",
  "SALMANS",
  "RADIO SHACK",
  "EXXESS",
  "ÓPTICA POPULAR",
  "ACA JOE",
  "PAYLESS SHOES",
  "PRINT PHOTO",
  "CALZA FLEX",
  "ENAMORA BOUTIQUE",
  "OPTICA LA CURACAO",
  "COOPERATIVA COACEHL",
  "DEBBIE´S CORNER",
  "JOYERÍA REY",
  "LOVABLE",
  "STARNET",
  "CAFÉ HABANA",
  "T CELULAR",
  "CASA YU-SHAN",
  "SU-SEE´S",
  "CELMOVIL",
  "FLORES Y MÁS",
  "HELADOS SARITA",
  "MINUTAS YOSHI",
  "TODOMOBIL",
  "HUSH",
  "BETCRIS",
  "ATM FULL BANRURAL",
  "EL CANARIO",
  "ADN STORE",
  "LUNAS",
  "REINO NATURAL",
  "DETODO",
  "COOPERATIVA ARSENAULT",
  "VOI DE VIAJE",
  "COCO BALEADAS",
  "ANIME´S DREAM",
  "CELULARES",
  "ZONA LIGHT",
  "OFICINAS ADMINISTRATIVAS PLAZA MIRAFLORES",
  "TIENDA TACTIL",
  "BUFETE DE ABOGADOS",
  "ISEL TRABEL",
  "ANDY’S BABER SHOP",
  "FAMA OPDF",
  "RAPUNZEL",
  "EXPONOVEDADES",
  "CEED (CENTRO DE ENDODONCIAS Y EMERGENCIAS DENTALES)",
  "MENDELS",
  "LA CURACAO",
  "BUFETE WILLIAMS",
  "KARLA’S NAILS & CARE",
  "BARBERÍA EMMANUEL",
  "THE GLAM STUDIO",
  "UNLIMITED",
  "MENDELS HOME",
  "INTERNET NAVIGATOR",
  "INMOBILIARIA LOS ROBLES",
  "CEMESAM",
  "BUFETE LEITZELAR",
  "PATTY’S ACCESORIOS",
  "TACO BALEADAS",
  "CHURCH´S CHICKEN",
  "PASEO UNIVERSITARIO",
  "ORIENTAL KING",
  "CHICKEN & FRIES",
  "SABOR CATRACHO",
  "BIGOS",
  "WENDY´S",
  "ESPRESSO AMERICANO",
  "FRESHLY MADE",
  "FÚTBOL DE ALTURA",
  "PRO FITNESS",
  "PIZZA HUT",
  "MASAJES TERAPÉUTICOS MANOS MILAGROSAS",
  "COSMETICS ROMATT",
  "SAMILA'S BOUTIQUE",
  "PAN Y MÁS",
  "VALERIA'S BOUTIQUE"

];

export default tiendas;