import React from 'react';
import FormComponent from './FormComponent';
import { Typography } from '@mui/material';


function FormPage() {
  return (
    <div>
      <FormComponent />
      
    </div>
  );
}

export default FormPage;